import { BasicList } from "../../components/common/BasicList";
import { FixedImage } from "../../components/common/FixedImage";
import { Section } from "../../components/common/Section";
import { Text } from "../../components/common/Text";
import { Header } from "../../components/Header/Header";

import { ABOUT_US, CHARACTERIZES_US, THANKS_TO_US, WE_WILL_DO_IT_FOR_YOU } from "../../utils/data.util";

import headerImg from '../../assets/rachunkowość.jpg';
import { SectionList } from "../../components/common/SectionList";

export const Home = () => {
    return (
        <div className="main__content home">
            <Header />
            <Section title="O firmie">
                <Text>
                    {ABOUT_US}
                </Text>
            </Section>
            <Section title="Dzięki nam:">
                <SectionList list={THANKS_TO_US} />
            </Section>
            <FixedImage
                src={headerImg}
                text="świadczy kompleksowe usługi księgowe dla firm, oferuje także ubezpieczenia zdrowotne, majątkowe i komunikacyjne. Biuro ubezpieczeniowe organizuje również szkolenia BHP. Biuro rachunkowe swoje usługi świadczy dla firm i osób prywatnych na terenie Krakowa i okolic."
                title="Biuro rachunkowo-ubezpieczeniowe Andrzej Dziurman"
            />
            <Section title="Cechuje nas:">
                <BasicList list={CHARACTERIZES_US} />
            </Section>
            <Section title="Zrobimy to za Ciebie:">
                <BasicList list={WE_WILL_DO_IT_FOR_YOU} />
            </Section>
        </div>
    );
} ;