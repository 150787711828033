import { ReactNode } from 'react';
import { SectionItem } from './SectionItem';

export interface SectionItemInterface {
    icon?: ReactNode;
    title?: string;
    value: string;
}

interface Props {
    list: SectionItemInterface[];
    className?: string;
    itemClassName?: string;
}

export const SectionList = ({ list, className, itemClassName }: Props) => {
    const getItems = () => {
        return list.map((i, index) => (
            <SectionItem
                key={String(index)}
                title={i?.title}
                value={i.value}
                icon={i?.icon}
                className={`section-list__item${itemClassName ? ' ' + itemClassName : ''}`}
            />)
        );
    };

    return (
        <ul className={`section-list${className? ' ' + className : ''}`}>
            {getItems()}
        </ul>
    );
};