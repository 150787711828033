import { Section } from "./Section";
import { Text } from "./Text";

interface Props {
    src: string;
    title?: string;
    text?: string;
}

export const FixedImage = ({ src, text, title }: Props) => {
    return (
        <section className="main__fixed-image fixed-image" style={{ backgroundImage: `url('${src}')` }}>
            {title && <Section title={title} className="fixed-image__section">
                {text && <Text className="fixed-image__text">
                    {text}
                </Text>}
            </Section>}
        </section>
    );
};