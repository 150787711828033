import { ReactNode } from "react";
import { Text } from "./Text";

interface Props {
    value: string;
    className?: string;
    icon?: ReactNode,
    title?: string;
}

export const SectionItem = ({ icon, title, value, className }: Props) => {
    return (
        <div className={`main__section-item section-item${className ? ' ' + className : ''}`}>
            {icon && icon}
            {title && <h3 className="section-item__title">{title}</h3>}
            <Text className="section-item__value">
                {value}
            </Text>
        </div>
    );
};