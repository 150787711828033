import { Image } from "../common/Image";

import headerImg from '../../assets/header.jpg';
import { Text } from "../common/Text";

export const Header = () => {
    return (
        <header className="home__header">
            <Image alt="Biuro" src={headerImg} className="img--fit" />
            <div className="home__header-content">
                <h1 className="home__header-title">Biuro rachunkowo-ubezpieczeniowe Andrzej Dziurman</h1>
                <Text className="home__header-text">
                    świadczy kompleksowe usługi księgowe dla firm, oferuje także ubezpieczenia zdrowotne, majątkowe i komunikacyjne. Biuro ubezpieczeniowe organizuje również szkolenia BHP. Biuro rachunkowe swoje usługi świadczy dla firm i osób prywatnych na terenie Krakowa i okolic.
                </Text>
            </div>
        </header>
    );
};